import type {
	CartValidation,
	CartValidationFail,
	NubeSDKRuntime,
	NubeSDKSendableEvent,
	WorkerMessage,
	WorkerMessageHandlerFunc,
} from "@tiendanube/nube-sdk-internal-types";

export function parseCartValidation(value: unknown): CartValidation {
	const val = value as CartValidationFail;
	if (val?.status === "fail" && typeof val.reason === "string") {
		return { status: "fail", reason: val.reason };
	}

	if ((value as CartValidation)?.status === "success") {
		return { status: "success" };
	}

	return { status: "pending" };
}

export const handleCartValidation: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			cart: {
				validation: parseCartValidation(changes?.cart?.validation),
			},
		},
	},
});

export const handleConfigSet: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			config: {
				has_cart_validation: changes?.config?.has_cart_validation || false,
			},
		},
	},
});

export const handleWebWorkerError: WorkerMessageHandlerFunc = (
	{ app, error },
	state,
) => ({
	apps: {
		[app]: {
			errors: [...(state.apps?.[app]?.errors || []), error],
		},
	},
});

const handlers = new Map<
	NubeSDKSendableEvent | "WebWorkerError",
	WorkerMessageHandlerFunc
>([
	["config:set", handleConfigSet],
	["cart:validate", handleCartValidation],
	["WebWorkerError", handleWebWorkerError],
]);

export function createWorkerMessageHandler(
	handlers: Map<
		NubeSDKSendableEvent | "WebWorkerError",
		WorkerMessageHandlerFunc
	>,
) {
	return (message: WorkerMessage, runtime: NubeSDKRuntime) => {
		const { app, type, changes } = message;
		const handler = handlers.get(type);

		if (handler) {
			runtime.send(app, type, (state) => handler(message, state));
			return;
		}

		if (changes) {
			runtime.send(app, type, () => changes);
			return;
		}

		runtime.send(app, type);
	};
}

export const workerMessageHandler = createWorkerMessageHandler(handlers);

export function createWorker(...parts: string[]): Worker {
	const blob = new Blob(parts, { type: "application/javascript" });
	const worker = new Worker(URL.createObjectURL(blob));
	return worker;
}
