{
  "name": "@tiendanube/nube-sdk-register",
  "description": "Nube SDK web worker register",
  "version": "0.1.4-alpha",
  "type": "module",
  "scripts": {
    "build": "tsup",
    "check": "biome check src/*",
    "check:fix": "biome check --write src/*",
    "test": "vitest run",
    "test:watch": "vitest"
  },
  "devDependencies": {
    "@biomejs/biome": "1.8.3",
    "@tiendanube/nube-sdk-internal-types": "*",
    "@vitest/ui": "^2.1.2",
    "tsup": "^8.2.4",
    "typescript": "^5.6.2",
    "vitest": "^2.1.0"
  }
}
