import type {
	AppID,
	NubeSDKRuntime,
	NubeSDKRuntimeEvent,
	NubeSDKRuntimeState,
	WorkerMessage,
} from "@tiendanube/nube-sdk-internal-types";
import { workerMessageHandler } from "./worker-handler";

export function addWorkerEventListener(
	id: AppID,
	worker: Worker,
	runtime: NubeSDKRuntime,
) {
	worker.addEventListener("message", (m: MessageEvent<string>) => {
		try {
			const message = JSON.parse(m.data) as WorkerMessage;

			if (String(message.app) === String(id)) {
				workerMessageHandler(message, runtime);
				return;
			}
			console.error(`App ${id} send message with invalid id ${message.app}`);
		} catch (err) {
			console.error("Unable to parse worker app message");
		}
	});

	runtime.on("*", (state: NubeSDKRuntimeState, event: NubeSDKRuntimeEvent) => {
		const { apps, ...runtimeState } = state;

		worker.postMessage(
			JSON.stringify({
				type: event,
				state: {
					...runtimeState,
					config: apps?.[id]?.config,
				},
			}),
		);
	});
}
